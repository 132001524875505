  :root {
    --bs-body-bg: #000;
    --bs-body-color: #fff;
    --bs-white-rgb: 255, 255, 255;
    --color-white: #fff;
    --font-helvetica: 'HelveticaNeue';
    /* --font-messina: 'Messina Sans';
    --font-sfpro: 'SF Pro Rounded'; */
  }

  * {
    box-sizing: border-box;
  }
html.lenis.lenis-smooth {
    scroll-behavior: auto!important;
  }

.adminPanel .add_field.centerContentFields{
    width: calc(50% - 15px) !important;
}

.adminPanel .add_field.centerContentFields input {
  margin-bottom: 16px;
}

.adminPanel .bottomTabsContent {
  width: 100%;
}

.adminPanel .bottomTabsContent .content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.adminPanel .bottomTabsContent .add_field.\35 0 {
  width: calc(50% - 15px) !important;
}

.adminPanel .bottomTabsContent .content .add_field {
  width: calc(33.33% - 30px);
}

.adminPanel .add_field.textareaField{
  width: 100% !important;
}

.adminPanel .add_field.form-group.udpateSection {
  display: flex;
  width: 100% !important;
  gap: 2%;
}

.adminPanel .add_field.form-group.udpateSection > div {
  width: 48%;
}

.adminPanel .itineraryContent .singleItinerary {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2%;
}

.adminPanel .itineraryContent .singleItinerary > div {
    width: 32%;
}

.adminPanel .itineraryContent .singleItinerary .detail_item_inner {
    width: 100%;
}

.adminPanel .itineraryContent .singleItinerary > div .detail_img_grid {
    display: block;
}

.adminPanel .detail_item.twoImages {
  display: flex;
  width: 100%;
  gap: 2%;
}

.adminPanel .detail_item.twoImages .detail_item {
  width: 100%;
}

.adminPanel .detail_item.twoImages .detail_item .detail_img_grid {
  display: block;
}

.adminPanel .add_field.textareaField > .contentFields .add_field {
  width: 48%;
  margin-bottom: 20px;
}

.adminPanel .add_field.textareaField .p-dropdown.p-component {
  width: 100%;
}

.adminPanel .add_field.textareaField > .contentFields {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
}

.adminPanel .addItineraryFields {
  display: flex;
  gap: 2%;
  width: 100%;
  align-items: center;
}

.adminPanel .add_field.itineraryFields {
  width: 100% !important;
}

.adminPanel .addItineraryFields .add_field {
  width: 22%;
}

.adminPanel .add_field.form-group.udpateSection > div input {
  margin-bottom: 20px;
}

.adminPanel .banner_img_wrap video {
  width: 100%;
}

.adminPanel .banner_img_wrap.videoWrap {
  padding-bottom: 0px;
}

body.adminPanel{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 18px;
    /* font-weight: 300;  */
    height: 100vh;
    background-color: #131313;
    color: var(--color-white);
  }
body.adminPanel::-webkit-scrollbar {
    height: 0;
    width: 0
  }
  .adminPanel .error-message {
    color: #fff;
    margin: 15px 0 0;
}
.adminPanel h1,.adminPanel h2,.adminPanel h3,.adminPanel h4,.adminPanel h5,.adminPanel h6{
    font-family: var(--font-helvetica);
    /* font-weight: 100; */
    letter-spacing: 0.05px;
    margin-top: 0;
    margin-bottom: 20px;
  }
.adminPanel h1, .adminPanel h2{
    font-size: 32px;
    line-height: 38px;
  }
.adminPanel h3{
    font-size: 28px;
    line-height: 34px;
  }
.adminPanel h4{
    font-size: 22px;
    line-height: 28px;
  }
.adminPanel h6{
    font-family: var(--font-messina);
    font-size: 16px;
  }
.adminPanel ul {
    margin-top: 0;
    margin-bottom: 0;
  }
.adminPanel .list-style-none{
    list-style: none;
  }
.adminPanel p{
    margin-top: 0;
    margin-bottom: 15px;
  }
.adminPanel p:last-of-type{
    margin-bottom: 0;
  }
.adminPanel a {
    text-decoration: none;
  }
.adminPanel.text-center{
    text-align: center;
  }
.adminPanel.w-100{
    width: 100%;
  }
.adminPanel.h-100{
    height: 100%;
  }
.adminPanelimg{
    max-width: 100%;
    display: block;
  }
.adminPanel.overlay-15::before {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #000;
    top: 0;
    left: 0;
    content: "";
    opacity: 15%;
  }
.adminPanel.letter-spacing-5 {
    letter-spacing: 0.05em;
  }
.adminPanel.letter-spacing-20 {
    letter-spacing: 0.2em;
  }
.adminPanel.font-helvetica{
    font-family: var(--font-helvetica);
  }
.adminPanel .font-messina,
  body.adminPanel .react-datepicker{
    font-family: var(--font-helvetica);
  }
.adminPanel.font-sfpro{
    font-family: var(--font-helvetica);
  }
.adminPanel
  .text-uppercase{
    text-transform: uppercase;
  }
.adminPanel.text-white{
    color: #fff;
  }
.adminPanel.text-dark,
  .text-dark h6,
  .text-dark h2,
  .text-dark p{
    color: #000;
  }
.adminPanel.bg-white {
    background-color: #fff;
  }
.adminPanel.font-38{
    font-size: 38px;
    line-height: 46px;
  }
.adminPanel.font-26{
    font-size: 26px;
    line-height: 32px;
  }
.adminPanel.font-22{
    font-size: 22px;
    line-height: 28px;
  }
.adminPanel.font-18{
    font-size: 18px;
    line-height: 35px;
  }
.adminPanel.font-14{
    font-size: 14px;
    line-height: 18px;
  }
.adminPanel.font-12{
    font-size: 12px;
    line-height: 15px;
  }
.adminPanel.fw-200{
    font-weight: 200;
  }
.adminPanel.fw-300{
    font-weight: 300;
  }
.adminPanel.p-0{
    padding: 0;
  }
.adminPanel.ptb-200{
    padding-top: 200px;
    padding-bottom: 200px;
  }
.adminPanel.ptb-120{
    padding-top: 120px;
    padding-bottom: 120px;
  }
.adminPanel.pb-120{
    padding-bottom: 120px;
  }
.adminPanel.pt-50{
    padding-top: 50px;
  }
.adminPanel.pt-40{
    padding-top: 40px;
  }
.adminPanel.pt-200{
    padding-top: 200px;
  }
.adminPanel.plr-100{
    padding-left: 100px;
    padding-right: 100px;
  }
.adminPanel.mt-200{
    margin-top: 200px;
  }
.adminPanel.m-0{
    margin: 0px;
  }
.adminPanel.mb-20{
    margin-bottom: 20px;
  }
.adminPanel.mb-40{
    margin-bottom: 40px;
  }
.adminPanel.flex-gap-20{
    gap: 20px;
  }
.adminPanel.position-relative{
    position: relative;
  }
.adminPanel.position-absolute{
    position: absolute;
  }
.adminPanel.top-0{
    top: 0;
  } 
  .start-0{
    left: 0;
  }
.adminPanel.z-1{
    z-index: 1;
  }
.adminPanel.z-2{
    z-index: 2;
  }
.adminPanel.overflow-hidden{
    overflow: hidden;
  }
.adminPanel.overflowx-hidden{
    overflow-x: hidden;
  }
.adminPanel.showonmobile{
    display: none;
  }
.adminPanel.hideonmobile {
    display: block;
  }
.adminPanel.light_green_dot {
    height: 5px;
    width: 5px;
    background: #A2BB3C;
    display: inline-block;
    border-radius: 50%;
  }
.adminPanel.object-fit-cover{
    object-fit: cover;
  }
.adminPanel
  /* Flex Classes */
  .d-flex{
    display: flex;
    flex-wrap: wrap;
  }
.adminPanel.align-items-center{
    align-items: center;
  }
.adminPanel.justify-content-center{
    justify-content: center;
  }
.adminPanel.justify-content-between{
    justify-content: space-between;
  }
.adminPanel.nowrap{
    flex-wrap: nowrap;
  }
.adminPanel.row-reverse{
    flex-flow: row-reverse;
  }
.adminPanel.flex-column{
    flex-direction: column;
  }
.adminPanel.d-grid{
    display: grid;
  }
.adminPanel
  /* Common Form style */
  div .btn-primary,
  div .btn-primary:hover,
  div .btn-primary:focus,
  div .btn-primary:active,
  :not(.btn-check)+.btn:active,
  .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{  
    background-color: rgba(var(--bs-white-rgb),0.25);
    border: none;
    font-size: 14px;
    font-family: var(--roboto-font);
    line-height: 18px;
    padding: 19px 20px;
    min-width: 200px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    --bs-bg-opacity: 1;  
    letter-spacing: 0.15em;
    border-radius: 50px;
    text-transform: uppercase;
  }
.adminPaneldiv .btn-primary:hover{
    background-color: rgba(var(--bs-white-rgb),0.35);
  }
.adminPanellabel.form-label {
    font-weight: 500;
    font-family: var(--roboto-font);
    letter-spacing: 0.02em;
  }
.adminPanel.form-check-input[type=radio],
  .form-check-input[type=checkbox]{
    display: none;
  }
.adminPanel.form-check-input[type=radio] + label,
  .form-check-input[type=checkbox] + label{
      background-color: rgba(var(--bs-white-rgb),0.25);
      border: none;
      font-size: 12px;
      font-family: var(--roboto-font);
      line-height: 18px;
      padding: 17px 39px 14px;
      min-width: 200px;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      --bs-bg-opacity: 1;
      letter-spacing: 0.15em;
      border-radius: 50px;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
  }
.adminPanel.form-check-input[type=radio]:checked + label,
  .form-check-input[type=checkbox]:checked + label {
    background: rgba(var(--bs-white-rgb),0.5);
    font-weight: 600;
  }
.adminPanelinput:focus,textarea:focus{
    outline: none;
  }
.adminPanelbutton{
    cursor: pointer;
  }
.adminPanel /* Header */
  .adminPanel header {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
.adminPanel header.sticky {
    background: rgba(0,0,0,0.4);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: sticky;
    top:0;
    z-index: 9;
  }
.adminPanel .header_top {
    width: 100%;
    text-align: center;
    padding: 30px;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    transition: all ease 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.adminPanel header.sticky a.header_logo img {
    max-height: 17px;
  }
.adminPanel header .header_top nav {
    margin-top: 10px;
    padding: 0 30px px;
  }
.adminPanel header .header_top nav > ul {
    margin: 0;
    padding: 0;
  }
.adminPanel header .header_top nav > ul > li {
    position: relative;
    display: flex;
    align-items: center;
  }
.adminPanel header .header_top nav > ul > li.active::after {
    position: absolute;
    content: "";
    height: 5px;
    width: 5px;
    background: #A2BB3C;
    border-radius: 50%;
    bottom: 11px;
    left: 0;
    right: 0;
    margin: auto;
  }
.adminPanel header .header_top nav > ul > li:not(:last-child) {
    margin-right: 40px;
  }
.adminPanel header .dropdown_menu {
    width: 170px;
    position: absolute;
    padding: 12px;
    top: 60px;
    background: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.3s;
  }
.adminPanel header .header_top nav > ul > li.expanded .dropdown_menu{
    top: 41px;
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
.adminPanel header:not(.sticky) .header_top nav > ul > li.expanded .dropdown_menu {
    top: 30px;
  }
.adminPanel header .dropdown_menu ul{
    padding: 0;
  }
.adminPanel header .dropdown_menu li:not(:last-child) {
    margin-bottom: 10px;
  }
.adminPanel header .dropdown_menu li, header .dropdown_menu li a {
    display: block;
    text-align: left;
  }
.adminPanel header .header_top nav > ul > li span.menuicon {
    display: inline-block;
    width: 16px;
    margin-left: 4px;
    cursor: pointer;
  }
.adminPanel header .header_top nav > ul > li span.menuicon img{
    transition: all ease 0.3s;
  }
.adminPanel header .header_top nav > ul > li.expanded img {
    transform: rotate(180deg);
  }
.adminPanel .hamburger {
    display: none;
  }
.adminPanel .hamburger .line{
    width: 35px;
    height: 2px;
    background-color: #ecf0f1;
    display: block;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
.adminPanel 
  .hamburger:hover{
    cursor: pointer;
  }
.adminPanel 
  .hamburger.is-active .line:nth-child(2){
    opacity: 0;
  }
.adminPanel .hamburger.is-active .line:nth-child(1){
    -webkit-transform: translateY(6px) rotate(45deg);
    -ms-transform: translateY(6px) rotate(45deg);
    -o-transform: translateY(6px) rotate(45deg);
    transform: translateY(6px) rotate(45deg);
  }
.adminPanel .hamburger.is-active .line:nth-child(3){
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
.adminPanel .mobile_menu_sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    padding: 0 50px 0 0;
    transition: all ease 0.3s;
    transform: translateX(-100%);
  }
.adminPanel .mobile_menu_sidebar.show_menu_bar {
    transform: none;
  }
.adminPanel .mobile_menu_wrapper {
    padding: 40px;
    background: #181818;
    height: 100%;
    position: relative;
  }
.adminPanel .mobile_menu_wrapper nav ul {
    flex-direction: column;
    padding: 0;
    gap: 10px;
  }
.adminPanel .mobile_menu_wrapper nav ul li {
    display: flex;
    flex-wrap: wrap;
  }
.adminPanel .mobile_menu_wrapper .menuicon {
    display: block;
    width: 18px;
  }
.adminPanel .mobile_menu_wrapper .dropdown_menu {
    position: static;
    opacity: 0;
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 100%;
    background: transparent;
  }
.adminPanel .mobile_menu_wrapper .expanded .dropdown_menu {
    opacity: 1;
    visibility: visible;
    height: auto;
    padding: 0 0 0 10px;
  }
.adminPanel .close_menu_bar {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    height: auto;
    cursor: pointer;
  }
.adminPanel .close_menu_bar .line {
    display: block;
    width: 35px;
    height: 2px;
    background: #fff;
    margin: 5px 0;
  }
.adminPanel .close_menu_bar .line:nth-child(1){
    -webkit-transform: translateY(7px) rotate(45deg);
    -ms-transform: translateY(7px) rotate(45deg);
    -o-transform: translateY(7px) rotate(45deg);
    transform: translateY(7px) rotate(45deg);
  }
.adminPanel .close_menu_bar .line:nth-child(2){
    -webkit-transform: translateY(-0px) rotate(-45deg);
    -ms-transform: translateY(-0px) rotate(-45deg);
    -o-transform: translateY(-0px) rotate(-45deg);
    transform: translateY(-0px) rotate(-45deg);
  }
.adminPanel .page_banner picture img{
    height: 100vh;
    object-fit: cover;
    width: 100%;
    min-height: 600px;
    object-position: bottom center;
  }
.adminPanel .main_home_banner::before {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    top: 0;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 10%, rgba(0, 0, 0, 0) 65%, #131313);
    top: 0;
  }
.adminPanel .main_home_banner_content {
    top: 0;
    left: 0;
    padding-top: 158px;
  }
.adminPanel .bannerbox {
    position: absolute;
    height: calc(100% - 100px);
    max-width: 415px;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    box-shadow: 0px 22px 70px 10px #000000d9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.adminPanel .home_banner_bottom {
    margin: 0 -50%;
    padding: 0 26%;
  }
.adminPanel .dotdivider span {
    height: 5px;
    width: 5px;
    background: #A2BB3C;
    display: inline-block;
    border-radius: 50%;
    margin: 35px 0;
  }
.adminPanel .dotdivider.big span {
    height: 10px;
    width: 10px;
  }
.adminPanel .slider_wrapper .swiper {
    padding: 66px 0 130px;
  }
.adminPanel .featured_work_slider .swiper-slide{
    transition: all ease 0.3s;
    position: relative;
    z-index: 1;
  }
.adminPanel .featured_work_slider  .swiper-slide.swiper-slide-active {
    transform: scale(1.2);
    position: relative;
    z-index: 2;  
  }
.adminPanel .featured_work_slider  .swiper-slide.swiper-slide-active:hover {
    transform: scale(1.25);
  }
.adminPanel .common_work_slide {
    height: 500px;  
  }
.adminPanel .work_slide_hover_content {
    bottom: 0;
    width: 100%;
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: none;
  }
.adminPanel .featured_work_slider .swiper-slide .common_work_slide > img {
    opacity: 35%;
    object-fit: cover;
  }
.adminPanel .featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide > img {
    opacity: 100%;
  }
.adminPanel .swiper-slide.swiper-slide-active .common_work_slide_text::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 50%;
    top: 0;
    left: 0;
    z-index: -1;
  }
.adminPanel .common_work_slide_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 2;
    justify-content: center;
  }
.adminPanel .featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text {
    padding: 105px 0px 155px;
  }
.adminPanel .workbox_head {
    margin: 0 -40px;
  }
.adminPanel .common_work_slide h2 {
    font-size: 28px;
    line-height: 32px;
  }
.adminPanel .common_work_slide_text  p {
    font-size: 10px;
    text-transform: uppercase;
  }
.adminPanel .featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text .work_slide_hover_content {
    display: flex;
  }
.adminPanel .featured_work_slider .swiper-slide.swiper-slide-active svg.showdefault {
    display: none;
  }
.adminPanel .common_work_slide svg.showdefault {
    margin-top: 30px;
  }
.adminPanel .common_work_slide svg.showonhover {
    margin-bottom: 28px;
  }
.adminPanel .common_slider_arrow {
    height: 45px;
    width: 45px;
    flex: 0 0 45px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid rgba(255,255,255,0.5);
    cursor: pointer;
  }
.adminPanel .slider_nav {
    margin: 20px 0;
    gap: 40px;
  }
.adminPanel .slider_scrollbar.swiper-scrollbar-horizontal {
    height: 2px;
    width: 100%;
    max-width: 245px;
  }
.adminPanel .slider_scrollbar.swiper-scrollbar-horizontal {
    height: 2px;
    max-width: 245px;
    background: rgba(245,245,245,0.3);
  }
.adminPanel .slider_scrollbar .swiper-scrollbar-drag {
    background: rgba(245,245,245,0.75);
  }
.adminPanel .project_link {
    margin-bottom: -40px;
    position: absolute;
    bottom: -12px;
    display: none;
  }
.adminPanel .featured_work_slider .swiper-slide.swiper-slide-active .project_link{
    display: flex;
  }
.adminPanel .underline_link::after {
    width: 65px;
    height: 0.5px;
    background: #fff;
    content: "";
    display: block;
    margin: 7px 0 0;
  }
.adminPanel 
  /* Home Gallery */
  .home_gallery_wrapper{
    max-width: 910px;
    margin: 0 auto;
  }
.adminPanel .home_gallery_image img, .home_gallery_image video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }
.adminPanel .home_gallery_middle{
    margin-top: -245px;
    gap: 10px;
  }
.adminPanel .home_gallery_top .home_gallery_image.gallery_image_1 {
    width: 316px;
    height: 444px;
    margin: auto;
  }
.adminPanel .home_gallery_image.gallery_image_1::after {
    position: absolute;
    content: "";
    height: 85%;
    background: linear-gradient(00deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    bottom: 0;
  }
.adminPanel .gallery_image_2 {
    width: 100%;
    max-width: 330px;
    height: 313px;  
  }
.adminPanel .gallery_image_3{
    width: 100%;
    max-width: 375px;
    height: 282px;
    margin-top: 183px;
  }
.adminPanel .gallery_image_4 {
    width: 152px;
    height: 213px;
    margin-left: 280px;
    margin-top: 0px;
  }
.adminPanel .home_gallery_image  .viewalllink {
    bottom: 40px;
    z-index: 1;
    left: 30px;
  }
.adminPanel .home_gallery_image .viewalllink::after {
    width: 95px;
    margin: 10px 0 0;
  }
.adminPanel 
  /* Service Slider */
  .common_service_slide {
    height: 620px;
    opacity: 20%;
  }
.adminPanel .common_service_slide img{  
    object-fit: cover;
  }
.adminPanel .swiper-slide-active .common_service_slide{
    opacity: 100%;
  }
.adminPanel .common_service_slide_text {
    padding: 100px 40px 40px;
  }
.adminPanel .common_service_slide_text::after {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    top: 0;
    left: 0;
    z-index: -1;
    content: "";
  }
.adminPanel .common_service_slide_text  h2 {
    margin-bottom: 35px;
    font-size: 35px;
  }
.adminPanel .common_service_slide_text ul.list-style-none li:not(:last-child) {
    margin-bottom: 23px;
    letter-spacing: 0.05px;
  }
.adminPanel .common_service_slide_text  ul.list-style-none {
    display: none;
  }
.adminPanel .swiper-slide.swiper-slide-active .common_service_slide_text  ul.list-style-none {
    display: block;
  }
.adminPanel .services_slider > h6 {
      margin-bottom: 56px;
  }
.adminPanel .services_slider .slider_nav {
      margin-top: 35px;
  }
.adminPanel .common_service_slide_text {
    padding: 100px 30px;
  }
.adminPanel .follow_media_grid{
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
.adminPanel .follow_milkbar_top_inner {
    gap: 30px;
    margin-bottom: 40px;
    padding: 0 100px;
    justify-content: left;
  }
.adminPanel a.follow_button {
    padding: 10px 28px 10px 28px;
    border-radius: 25px;
    background: rgba(255,255,255,0.1);
    color: #fff;
    border: 0.5px solid #575757;
    font-size: 14px;
    letter-spacing: 0.05px;
    display: inline-block;
  }
.adminPanel .follow_milkbar_bottom .swiper-slide {
    transition: all ease 0.3s;
  }
.adminPanel .follow_milkbar_bottom .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide,
  .follow_milkbar_bottom .swiper-slide.swiper-slide-active {
    opacity: 0.3;
  }
.adminPanel .inner_page_banner::before,
  .inner_page_banner::after{
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
  }
.adminPanel .inner_page_banner::before{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    height: 30%;
    top: 0;    
  }
.adminPanel .inner_page_banner::after {
    background: linear-gradient(0deg, rgb(19 19 19) 10%, rgba(0, 0, 0, 0) 100%);
    height: 50%;
    bottom: 0;
  }
.adminPanel .about_content {
    max-width: 750px;
    margin: -180px auto 0;
    padding: 0 15px;
  }
.adminPanel 
  /* contact page */
  .contact_form_wrap {
    max-width: 900px;
    margin: -180px auto 0;
  }
.adminPanel .option_types_radios {
    max-width: 630px;
    margin: auto;
    gap: 12px;
  }
.adminPanel .contact_form_wrap .form-group {
    text-align: center;
    margin-bottom: 45px;
  }
.adminPanel .contact_form_wrap .form-group.textareafld {
    padding: 40px 0;
  }
.adminPanel .option_types_radios input[type=checkbox] + label {
    padding: 20px 45px 17px;
    font-size: 18px;
    display: inline-block;
    font-family: 'SF Pro Rounded';
    text-transform: inherit;
    letter-spacing: 0.05px;
    min-width: auto;
    height: auto;
    background: rgba(255,255,255,0.10);
  }
.adminPanel .contact_form_wrap .form-group input, .contact_form_wrap .form-group textarea {
    max-width: 360px;
    width: 100%;
    background: transparent;
    border-width: 0 0 0.6px 0;
    font-size: 18px;
    font-family: 'SF Pro Rounded';
    letter-spacing: 0.05px;
    margin: 0 auto;
    color: #fff;
  }
.adminPanel .contact_form_wrap .form-group input[type="text"],
.adminPanel .contact_form_wrap .form-group input[type="email"] {
    height: 46px;
    vertical-align: top;
    line-height: 1;
    text-align: center;
    padding-bottom: 25px;
    color: #fff;
    font-size: 18px;
    font-family: 'SF Pro Rounded';
  }
.adminPanel .contact_form_wrap .form-group textarea{
    height: 120px;
  }
.adminPanel .contact_form_wrap  button {
    background: transparent;
    border: none;
    font-size: 16px;
  }
.adminPanel .contact_form_wrap  button::after {
    width: 95px;
    margin: 10px 0 0;
  }
.adminPanel .message  p.font-18 {
    margin: 5px 0 10px;
  }
.adminPanel 
  /* Project Page */
  .file-upload ::file-selector-button {
   background-color: transparent;
   border: none;
  }
.adminPanel .file-upload .i-pic-upload {
    position: absolute;
    color: #273142;
    outline: none;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    width: 15px;
    height: 15px;
    border-radius: 0px 3px 3px 0px;
    pointer-events: none;
  }
.adminPanel .form-login .add_field {
    width: calc(33.33% - 30px);
  }
.adminPanel .add_field input, .adminPanel .add_field select, .adminPanel .add_field textarea {
    padding: 15px 15px;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 4px;
    background: #fff;
    height: 50px;
    line-height: normal;
    font-size: 15px;
    color: #000;
}
.adminPanel .form-login .add_field.titleField {
  width: 100%;
}
.adminPanel .form-login .add_field.textareaField {
  width: 100%;
}
.adminPanel .form-login .add_field.textareaField textarea {
  height: 150px;
}
.adminPanel .add_project button {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    display: inline-block;
    font-size: 15px;
    letter-spacing: .05px;
    padding: 10px 15px;
    max-width: fit-content;
    font-weight: 600;
  }
.adminPanel .project_banner .bannerbox {
    align-items: flex-end;
    padding-bottom: 60px;
  }
.adminPanel .project_banner .bannerbox .home_banner_bottom {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
.adminPanel .project_banner .bannerbox .home_banner_bottom h1 {
    margin-bottom: 33px;
  }
.adminPanel .brand_direction {
    max-width: 510px;
    margin: 80px auto 0;
    padding: 0 15px;
  }
.adminPanel .brand_direction  p {
    font-size: 18px;
    line-height: 30px;
  }
.adminPanel .brand_direction  .brand_logo {
    margin-top: 110px;
    gap: 30px
  }
.adminPanel .common_brand_logo:not(.active) {
    opacity: 40%;
  }
.adminPanel .seamless_booking_head{
    max-width: 460px;
    margin: 0 auto;
  }
.adminPanel .dotdivider.mtb15 span {
    margin: 15px 0;
  }
.adminPanel .seamless_booking_slider_wrapper .swiper {
    padding: 50px 0;
  }
.adminPanel .seamless_booking_slider_wrapper .swiper-slide {
    transition: all ease 0.3s;
    position: relative;
    z-index: 1;
  }
.adminPanel .seamless_booking_slider_wrapper .swiper-slide.swiper-slide-active {
    transform: scale(1.2);
    position: relative;
    z-index: 2;
  }
.adminPanel .more_project_slider .swiper-slide.swiper-slide-active .common_work_slide_text {
    padding-bottom: 40px;
    justify-content: flex-end;
  }
.adminPanel .more_project_slider .swiper-slide.swiper-slide-active .common_work_slide_text::after {
    height: 40%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    top: auto;
    bottom: 0;
  }
.adminPanel .project_verticle_images{
    max-width: 910px;
    padding: 0 15px;
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }
.adminPanel .project_verticle_images .project_verticle_image:first-child{
    padding-right: 140px;
  }
.adminPanel .project_verticle_images .project_verticle_image:last-child {
    padding-left: 140px;
    margin-top: 215px;
  }
.adminPanel .verticle_overlap_text {
    background-color: rgba(0,0,0,0.3);
    box-shadow: 0px 4px 4px 0px #00000040;
    max-width: 270px;
    border-radius: 5px;
    padding: 26px 30px;
  }
.adminPanel .project_verticle_images .project_verticle_image:first-child .verticle_overlap_text{
    top: 80px;
    right: 0px;
  }
.adminPanel .project_verticle_images .project_verticle_image:last-child .verticle_overlap_text{
    top: 230px;
    left: 0px;
  }
.adminPanel .verticle_overlap_text p {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
  }
.adminPanel .project_verticle_image  img {
    border-radius: 10px;
    overflow: hidden;
  }
.adminPanel 
  
  /* Social Media */
  .text_block{
    max-width: 690px;
    padding: 0 20px;
    margin: 0 auto;
  }
.adminPanel .common_social_slider {
    display: flex;
    flex-wrap: wrap;
    padding-top: 150px;
  }
.adminPanel .common_social_slider > div{
    flex: 0 0 50%;
    max-width: 50%;
  }
.adminPanel .social_slider_slide {
    height: 500px;
    border-radius: 6px;
    overflow: hidden;
  }
.adminPanel .rtlSlider-swiper-custom-scrollbar {
    direction: rtl;
  }
.adminPanel .common_social_slider .slider_scrollbar.swiper-scrollbar-horizontal {
      max-width: 225px;
      margin-top: 45px;
  }
.adminPanel .rtlSlider-swiper-custom-scrollbar.slider_scrollbar {
    margin-left: auto;
  }
.adminPanel .social_ltrslider_outer .social_ltrslider_left,
  .social_rtlslider_outer .social_rtlslider_left {
    padding: 8% 9% 0 9%;
  }
.adminPanel .common_social_slider p {
    font-size: 18px;
    line-height: 25px;
  }
.adminPanel .envisioning {
    margin-top: 150px;
  }
.adminPanel .social_media_page h6.workhead {
    text-align: center;
    padding: 0 20px;
    margin: 100px 0 0 0;
    display: none;  
  }
.adminPanel .project_list_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 40px;
    column-gap: 10px;
  }
.adminPanel .project_list_wrapper .project_link {
      display: block;
  }
.adminPanel .project_list_wrapper > div {
    margin-bottom: 60px;
  }
.adminPanel .project_list_wrapper .common_work_slide::after {
    position: absolute;
    content: "";
    height: 100%;
    width: calc(100% - 80px);
    background: #000;
    opacity: 50%;
    top: 0;
    left: 40px;
  }
.adminPanel .project_list_wrapper .common_work_slide {
    padding: 0 40px;
  }
.adminPanel .project_list_wrapper .common_work_slide img {
    width: calc(100% - 80px);
    left: 40px;
  }
.adminPanel .project_list_wrapper .common_work_slide_text {
    left: 0;
  }
.adminPanel .project_list_wrapper .workbox_head {
    margin: 0;
  }
.adminPanel .services_tabs_panel {
    padding-left: 100px;
  }
.adminPanel .service_tabs_wrapper {
    display: flex;
    flex-wrap: wrap;
  }
.adminPanel .service_tab_images, .service_tab_items {
    flex: 0 0 auto;
    width: 50%;
  }
.adminPanel .service_tab_images {
    padding: 0 0 0 30px;
  }
.adminPanel .service_tab_items {
    display: flex;
    align-items: center;
  }
.adminPanel .service_tabs_wrapper .swiper.swiper-vertical {
    height: auto;
    margin: 0;
  }
.adminPanel .service_tabs_wrapper .swiper-slide {
    height: auto !important;
  }
.adminPanel .service_tab_images .swiper-slide {
    display: flex;
    align-items: center;
  }
.adminPanel .service_tab_item {
    display: flex;
    column-gap: 50px;
  }
.adminPanel .service_desc {
    display: flex;
    gap: 20px;
    max-width: 300px;
  }
.adminPanel .service_desc .icon {
    display: block;
    width: 50px;
    height: auto;
  }
.adminPanel .service-name h3 {
    font-size: 30px;
    line-height: 30px;
    transition: all ease 0.3s;
    opacity: 0.6;
    font-weight: 200;
  }
.adminPanel .service_desc {
    display: flex;
    gap: 20px;
    max-width: 300px;
    transition: all ease 0.3s;
    opacity: 0;
    visibility: hidden;
  }
.adminPanel .service_desc .text strong {
    font-weight: 500;
  }
.adminPanel .swiper-slide-thumb-active .service_desc {
    opacity: 1;
    visibility: visible;
  }
.adminPanel .swiper-slide-thumb-active .service-name h3 {
      opacity: 1;
  }
.adminPanel .services_tabs_panel .slider_nav {
    display: none;
  }
.adminPanel .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
    width: 100% !important;
  }
.adminPanel .mobile_tab_content {
    width: 100%;
    height: 540px;
  }
.adminPanel 
  .mobile_tab_content .wrapper {
    padding: 60px 30px 20px;
    height: 100%;
    position: relative;
    z-index: 1;
  }
.adminPanel mobile_tab_content .tab_content_heading h3 {
    margin: 0 0 30px;
  }
.adminPanel .mobile_tab_content .tab_content_wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
.adminPanel .mobile_tab_content .tab_content_wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
  }
.adminPanel .site_footer {
    padding: 100px 0 250px;
    position: relative;
  }
.adminPanel .site_footer:after {
    content: '';
    position: absolute;
    top: 190px;
    width: 100%;
    height: calc(100% - 190px);
    background: linear-gradient(179deg, #131313, transparent);
  }
.adminPanel .footer_bg_image {
    position: absolute;
    top: auto;
    height: calc(100% - 200px);
    width: 100%;
    bottom: 0;
  }
.adminPanel .footer_bg_image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
.adminPanel .footer_wrapper {
    padding: 0 100px;
    gap: 50px;
    position: relative;
    z-index: 5;
  }
.adminPanel .footer_menu h3.menu_title {
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 40px;
    letter-spacing: 0.10em;
    text-transform: uppercase;
  }
.adminPanel .footer_menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
.adminPanel .footer_menu ul li a {
    color: #fff;
    font-family: 'SF Pro Rounded';
    font-weight: 100;
    letter-spacing: 0.05em;
    opacity: 0.6;
    transition: all ease 0.3s;
  }
.adminPanel .footer_menu ul li.active a,
  .footer_menu ul li:hover a {
    opacity: 1;
  }
.adminPanel input:-webkit-autofill {
      -webkit-box-shadow:0 0 0 50px #121212 inset; 
      -webkit-text-fill-color: #fff;
  }
.adminPanel input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px #121212 inset;/*your box-shadow*/
      -webkit-text-fill-color: #fff;
  
  } 
.adminPanel .login-title {
    text-align: center;
    margin: 0;
  }
.adminPanel #login-page {
      background: #121212;
      padding: 50px 0;
      display: flex;
      height: 100vh;
  }
.adminPanel .notice {
    font-size: 13px;
    text-align: center;
    color: #666;
    margin-bottom: 30px;
  }
.adminPanel .login {
      width: 100%;
      height: auto;
      background: #FFF;
      padding: 40px;
      max-width: 400px;
      margin: auto;
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 30px;
  }
.adminPanel .login a {
    margin-top: 25px;
    text-align: center;
  }
.adminPanel .form-login {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    gap: 30px;
    margin-bottom:30px;
  }
.adminPanel #login-page .form-login{
    gap: 0;
    margin-bottom:0px;
  }
.adminPanel .form-login label {
      text-align: left;
      font-size: 16px;
      margin-top: 10px;
      margin-left: 15px;
      display: block;
      color: #000;
      margin-bottom: 5px;
      font-weight: 600;
      letter-spacing: 0;
}
.adminPanel .main-section table.projects-table td p {
  color: #fff;
}
.adminPanel .detail_item .detail_item_inner p {
  color: #fff;
}
.update_details_wrap span.p-calendar {
  width: 100%;
}
.p-accordion-header {
  margin: auto;
  padding: inherit;
}

.projects-inner .p-accordion-header a {
  padding: 20px 10px;
}
.projects-inner .p-accordion-tab {
  margin: 0px 0px 10px 0px;
}
.adminPanel .projects-inner p {
  color: #fff;
}
.adminPanel .projects-inner p.dataNotFound {
  text-align: center;
}
.p-accordion-content .singleMenuItem p {
  color: #000;
}
.p-accordion-content .singleMenuItem {
  padding: 10px 20px;
}
.adminPanel .actionMenuBtn {
  color: #000 !important;
  border: 1px solid #000 !important;
}
.adminPanel p.successMessage {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.adminPanel .input-email, .input-password {
      width: 100%;
      background: #121212;
      border-radius: 25px;
      margin: 4px 0 15px 0;
      padding: 15px 12px;
      display: flex;
      color: #fff;
  }
.adminPanel .form-login i.icon {
      color: #fff;
  }
.adminPanel .icon {
    padding: 4px;
    color: #666;
    min-width: 30px;
    text-align: center;
  }
.adminPanel #login-page .form-login input[type="email"],
.adminPanel #login-page .form-login input[type="text"],
.adminPanel #login-page .form-login input[type="password"] {
    width: 100%;
    border: 0;
    background: none;
    font-size: 15px;
    padding: 0;
    color: #fff;
    outline: none;
}

span#calendar-timeonly_starttime, span#calendar-timeonly_endtime {
  width: 100%;
}

.adminPanel .project_detail_wrap input[type="text"] {
  width: 100%;
  border: 0;
  font-size: 15px;
  padding: 0;
  color: #fff;
  outline: none;
}
.adminPanel .form-login label input[type="checkbox"] {
      cursor: pointer;
  }
.adminPanel .form-login input::placeholder{
    color: #fff;
  }
.adminPanel .form-login button[type="submit"] {
      width: 100%;
      border: 0;
      border-radius: 25px;
      padding: 18px 14px;
      background: #121212;
      color: #FFF;
      display: inline-block;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      margin-top: 10px;
      transition: ease all 0.3s;
  }
.adminPanel .form-login button[type="submit"]:hover {
    opacity: 0.9;
  }
.adminPanel .dashboard-nav {
    background: #181818;
    display: inline-block;
    position: fixed;
    top: 0px;
    left: 0;
    height: 100vh;
    width: 220px;
    max-width: 100%;
    z-index: 10;
    overflow: auto;
  }
.adminPanel 
  .dashboard-nav .logo a{
  color: #fff;
  display: block;
  text-decoration: none;
  text-align: center;
  padding: 20px 0;
  transition: all 200ms ease;
  background: #121212;
  }
.adminPanel 
  .dashboard-nav .logo .title{
  display: block;
  font-size: 1em;
  }
.adminPanel .dashboard-nav .logo .fa{
  font-size: 68px;
  }
.adminPanel 
  .main-nav{
  margin: 0;
  padding: 20px 0;
  list-style: none;
}
.adminPanel .form-login.addMenuForm {
    color: #000;
}
.adminPanel .form-login.addMenuForm input[type="text"] {
    border: 1px solid #000;
    color: #000;
    padding: 0px 10px;
}
.adminPanel .main-nav a span {
    margin-right: 5px;
  }
.adminPanel .main-nav a{
  display: block;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 0.875em;
  padding: 1em 0 1em 20px;
  transition: background 200ms ease;
  }
.adminPanel .main-nav a:hover {
    background: #374151;
  }
.adminPanel .dashboard {
    width: calc(100% - 220px);
    margin-left: auto;
  }
.adminPanel .dashboard .container {
    padding: 24px;
    background: #000;
    height: 100vh;
    overflow: auto;
    max-width: 100%;
  }
.adminPanel .dash_title {
    padding-bottom: 30px;
  }
.adminPanel 
  .projects {
    background-color: #121212;
    border: 1px solid #121212;
    width: 100%;
    height: 100vh;
    border-radius: 20px;
  }
.adminPanel 
  .projects-header {
    color: white;
    padding: 22px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.adminPanel .project_search {
    display: none;
    align-items: center;
    gap: 20px;
  }
.adminPanel .project_search input {
    border: none;
    outline: none;
    border-radius: 30px;
    padding: 10px 15px;
    line-height: 1;
    font-size: 12px;
    color: #111827;
  }
.adminPanel .project_search input::placeholder{
  color: #111827;
  }
.adminPanel .project_search a.btn {
    text-decoration: none;
    color: #111827;
    background: #fff;
    border: 1px solid transparent;
    display: inline-block;
    font-size: 12px;
    padding: 10px 15px;
    line-height: 1;
    border-radius: 30px;
  }
.adminPanel .project_search form {
    display: flex;
    align-items: center;
    gap: 6px;
  }
.adminPanel .add_project a {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    display: inline-block;
    font-size: 15px;
    letter-spacing: .05px;
    padding: 10px 15px;
    max-width: fit-content;
    font-weight: 600;
  }
.adminPanel .view_detail {
    text-decoration: none;
    width: 100%;
    background: #ffffff1a;
    border: 1px solid transparent;
    border-radius: 25px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    letter-spacing: .05px;
    padding: 10px 28px;
    max-width: fit-content;
  }
.adminPanel 
  .count {
      padding-left: 10px; /* Adjust space as needed */
  }
.adminPanel .title {
  font-size: 21px;
  display: flex;
  align-items: center;
  }
.adminPanel 
  .count {
  color: #fff;
  font-size: 16px;
  }	
  .title {
  font-size: 21px;
  }
.adminPanel .projects-inner {
    background: #1f2937;
    padding: 25px;
    border-radius: 10px;
  }
.adminPanel .projects-table {
    width: 100%;
    border-spacing: 10px;
  }
.adminPanel .projects-table td,
  .projects-table th {
  color: white;
  padding: 10px 22px;
  vertical-align: middle;
  text-align: center;
  border-radius: 10px;
  border-bottom: 1px solid;
  }
.adminPanel 
  .projects-table td p {
  font-size: 15px;
  margin: 0;
  }
.adminPanel 
  .projects-table th {
    background-color: #374151;
    font-weight: 500;
    border-radius: 10px;
  }
.adminPanel .projects-table tr:nth-child(even) {
    background: #374151;
  }
.adminPanel .brand_sec_wrap {
    width: calc(100% - 220px);
    margin-left: auto;
  }
.adminPanel .brand_container, 
  .add_project_main {
    background: #1f2937;
    padding: 25px;
    border-radius: 10px;
  }
.adminPanel .add_project_main .add_field h2 {
    font-size: 18px;
    margin: 0 0 10px;
    font-weight: 500;
    line-height: normal;
  }
.adminPanel .brand_inner_wrap {
    padding: 24px;
    background: #000;
    height: 100vh;
    overflow: auto;
  }
.adminPanel .brand_grid {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
.adminPanel .brand_grid .brand_item {
    color: #ffffff;
    text-align: left;
    background: #374151;
    box-shadow: none;
    min-width: inherit;
    padding: 20px;
    border-radius: 10px;
  }
.adminPanel .add_booking {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    display: inline-block;
    font-size: 15px;
    letter-spacing: .05px;
    padding: 10px 15px;
    max-width: fit-content;
    font-weight: 600;
  }
.adminPanel .brand_image_wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
.adminPanel .brand_img_item {
    position: relative;
    padding-bottom: 102%;
  }
.adminPanel .brand_img_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
.adminPanel .brand_container button, 
  .form_submit_btn button {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    display: inline-block;
    font-size: 15px;
    letter-spacing: .05px;
    padding: 10px 15px;
    max-width: fit-content;
    font-weight: 600;
  }
.adminPanel .brand_edit_btn {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    justify-content: flex-end;
  }
.adminPanel .brand_form_wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
  }
.adminPanel .brand_form_wrap .form_item {
    width: 33.33%;
    padding-left: 20px;
    padding-bottom: 20px;
  }
.adminPanel .brand_form_wrap .form_item input {
    width: 100%;
    padding: 15px 20px;
    outline: none;
    border: none;
    border-radius: 10px;
    background: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #000;
  }
.adminPanel .brand_form_wrap .form_item label {
    display: block;
    font-size: 18px;
    margin: 0 0 10px;
    font-weight: 500;
  }
.adminPanel .form_submit_btn {
    width: 100%;
    padding-left: 20px;
  }
.adminPanel input[type=file]::-webkit-file-upload-button {
    display: block;
    width: 0;
    height: 0;
  background-color: transparent;
  padding: 0;
  outline: none;
  border: none;
  }
.adminPanel 
  .back_btn_wrap .back-btn {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    display: inline-block;
    font-size: 15px;
    letter-spacing: .05px;
    padding: 10px 15px;
    max-width: fit-content;
    font-weight: 600;
  }
.adminPanel .back_btn_wrap {
    text-align: right;
    margin-bottom: 30px;
  }
.adminPanel .project_detail_wrap .detail_item_inner, 
  .booking_details_wrap .detail_item_inner {
    text-align: left;
    background: #374151;
    box-shadow: none;
    min-width: inherit;
    padding: 20px;
    border-radius: 10px;
  }
.adminPanel .project_detail_wrap .detail_item h2, 
  .booking_details_wrap .detail_item h2 {
    margin: 0 0 15px;
    font-size: 20px;
    line-height: normal;
    text-transform: capitalize;
    font-weight: 600;
  }
.adminPanel .project_detail_wrap .detail_item_inner p, 
  .booking_details_wrap .detail_item_inner p {
    font-size: 15px;
    line-height: normal;
  }
.adminPanel .project_detail_wrap .detail_item, 
  .booking_details_wrap .detail_item {
    margin: 0 0 35px;
  }
.adminPanel .project_detail_wrap .gallery_inner {
    background: #fff;
    border-radius: 0;
  }
.adminPanel .gallery_inner .detail_img_item {
    position: relative;
    padding-bottom: 76%;
  }
.adminPanel  .gallery_inner .detail_img_item img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
.adminPanel .project_detail_wrap .gallery_inner .detail_img_grid {
    grid-template-columns: repeat(6, 1fr);
  }
.adminPanel .detail_banner_wrap .detail_img_item {
    background: #fff;
    padding: 20px;
  }
.adminPanel .detail_banner_wrap .detail_img_item h2 {
    color: #000;
  }
.adminPanel .banner_img_wrap {
    position: relative;
    padding-bottom: 56%;
  }
.adminPanel .banner_img_wrap img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
.adminPanel .detail_project_btn_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 35px;
  }
.adminPanel .detail_edit_del .detail_btns {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    display: inline-block;
    font-size: 15px;
    letter-spacing: .05px;
    padding: 10px 15px;
    max-width: fit-content;
    font-weight: 600;
  }
.adminPanel .detail_edit_del {
    display: flex;
    align-items: center;
    gap: 10px;
  }
.adminPanel .update_detail_wrap .back-btn {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    display: inline-block;
    font-size: 15px;
    letter-spacing: .05px;
    padding: 10px 15px;
    max-width: fit-content;
    font-weight: 600;
  }
.adminPanel .update_details_wrap .form-group {
    margin: 0 0 35px;
  }
.adminPanel .update_details_wrap .form-group label {
    display: block;
    margin: 0 0 15px;
    font-size: 20px;
    line-height: normal;
    text-transform: capitalize;
    font-weight: 600;
  }
.adminPanel .update_details_wrap .form-group input, .adminPanel .update_details_wrap .form-group select, .adminPanel .update_details_wrap .form-group textarea {
    width: 100%;
    padding: 15px 20px;
    outline: none;
    border: none;
    border-radius: 10px;
    background: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    color: #000;
}
.adminPanel .update_details_wrap .form-group.textareaField textarea {
  height: 150px;
}
.adminPanel .update_details_wrap .form-group input[type="file"] {
    width: fit-content;
    padding: 10px 20px;
  }
.adminPanel .update_details_wrap .gallery_inner {
    background: #fff;
    box-shadow: none;
    padding: 20px;
  }
.adminPanel .detail_image_item {
    text-align: right;
  }
.adminPanel .update_details_wrap {
    background: #1f2937;
    padding: 25px;
    border-radius: 10px;
  }
.adminPanel .booking_detail_img_grid .detail_img_item img {
    max-width: 200px;
  }
.adminPanel .update_booking_detail .upload_item {
    margin: 0 0 20px;
  }
.adminPanel .content .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
          }
.adminPanel         .content .header button {
              padding: 10px 20px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
          }
.adminPanel         .content .header .btn-primary {
              background-color: #007bff;
              color: #ffffff;
          }
.adminPanel         .content .header .btn-secondary {
              background-color: #f1f1f1;
              color: #333;
          }
.adminPanel         .main-section_dashboard .cards {
            display: grid;
            margin-bottom: 20px;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
        }
.adminPanel         .card i {
              font-size: 30px;
              margin-bottom: 10px;
          }
.adminPanel         .main-section_dashboard {
            background: #1f2937;
            padding: 25px;
            border-radius: 10px;
        }
.adminPanel       .main-section_dashboard .card {
          color: #ffffff;
          text-align: left;
          background: #374151;
          box-shadow: none;
          min-width: inherit;
          padding: 20px;
          border-radius: 10px;
      }
.adminPanel     .main-section_dashboard .card .number {
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 8px;
    }
.adminPanel   .main-section_dashboard .card .label {
      font-size: 14px;
      font-weight: 300;
      color: #fff;
  }
.adminPanel .main-section_dashboard .user-info {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
.adminPanel .main-section_dashboard .user-info .details {
    background: #374151;
    padding: 25px;
    border-radius: 10px;
  }
.adminPanel         .user-info img {
              border-radius: 50%;
              margin-right: 10px;
          }
.adminPanel         .user-info .details {
              flex: 1;
          }
.adminPanel         .user-info .details .name {
              font-weight: 500;
              text-transform: capitalize;
          }
.adminPanel         .user-info .details .handle {
              color: #888;
          }
.adminPanel         .main-section_dashboard .user-info .details .tweet {
            margin-top: 10px;
            font-size: 14px;
            line-height: 1.4em;
            color: #fff;
        }
.adminPanel       .update_details_wrap .detail_img_item {
          text-align: right;
      }
.adminPanel     .update_details_wrap .remove_btn {
        background: #1f2937;
        border: none;
        color: #fff;
        padding: 5px 10px;
        border-radius: 10px;
        font-weight: 600;
        line-height: normal;
        font-size: 11px;
        margin-top: 10px;
    }
.adminPanel   .detail_banner_wrap .detail_img_grid {
      grid-template-columns: repeat(2, 1fr);
  }
.adminPanel .update_details_wrap .detail_banner_wrap .detail_img_grid {
    display: flex;
    flex-direction: column;
  }
.adminPanel .update_details_wrap .detail_banner_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
.adminPanel .update_details_wrap .detail_banner_wrap .form-group {
    margin: 0;
  }
.adminPanel .update_btn_wrap .update_btn {
    background: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    display: inline-block;
    font-size: 15px;
    letter-spacing: .05px;
    padding: 10px 15px;
    font-weight: 600;
  }
.adminPanel .update_btn_wrap {
    text-align: center;
  }
.adminPanel  .success-message {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }
.adminPanel 
  /* Details page css  */
  .detail_item h2 {
    margin: 0 0 30px;
    text-transform: uppercase;
  }
.adminPanel .detail_img_grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(6, 1fr);
  }
.adminPanel .detail_img_item img {
    width: 100%;
    display: block;
  }
.adminPanel .detail_item {
    margin: 0 0 50px;
  }
.adminPanel 
  .projects .title {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 18px;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;
  }
.adminPanel .projects .title .count {
    font-weight: 300;
    text-transform: capitalize;
    padding-left: 0;
  }
  /* Responsive Css */
  @media(max-width: 1024px) and (min-width: 769px){
  .featured_work_slider .workbox_head {
      margin: 0px;
  }
}
  
  @media(max-width: 1280px){
    .project_list_wrapper {
      grid-template-columns: repeat(3, 1fr);
    }
}
@media(max-width: 1024px){
  .project_list_wrapper.plr-100 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media(max-width: 992px){
    .project_verticle_images .project_verticle_image:first-child {
      padding-right: 70px;
    }
.project_verticle_images .project_verticle_image:last-child {
      padding-left: 70px;
    }
}
@media(max-width: 768px){
    .ptb-120 {
      padding-top: 80px;
      padding-bottom: 80px;
    }
.adminPanel   .pb-120{
      padding-bottom: 80px;
    }
.adminPanel   .plr-100 {
      padding-left: 15px;
      padding-right: 15px;
    }
.adminPanel   h1, h2{
      font-size: 28px;
    }
.adminPanel   .showonmobile{
      display: block;
    }
.adminPanel   .hideonmobile {
        display: none;
      }
.adminPanel     header .header_top nav {
        display: none;
      }
.adminPanel     .header_contact {
          margin: 0 20px 0 auto;
      }
.adminPanel     .hamburger {
        display: block;
      }
.adminPanel   .featured_work_slider .swiper-slide .common_work_slide > img {
      max-width: 65%;
      margin: auto;
      left: 0;
      right: 0;
    }
.adminPanel   .featured_work_slider  .swiper-slide.swiper-slide-active {
      transform: scale(1);
    }
.adminPanel   .swiper-slide.swiper-slide-active .common_work_slide_text::after {
      width: calc(100% - 35%);
      background: #000;
      right: 0;
      margin: auto;
    }
.adminPanel   .common_work_slide {
      margin-bottom: 124px;
    }
.adminPanel   .featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text {
      padding: 105px 10px 155px;
    }
.adminPanel   .featured_work_slider {
      padding-bottom: 0;
    }
.adminPanel   .home_gallery_block {
      padding-bottom: 50px;
    }
.adminPanel   .main_home_banner_content  .plr-100 {
      padding-left: 10px;
      padding-right: 10px;
    }
.adminPanel   .home_banner_bottom  h1 {
      font-size: 24px;
      line-height: 30px;
    }
.adminPanel   .home_banner_bottom {
        padding: 0 32%;
        margin: 0 -70px;
    }
.adminPanel   .brand_direction {
      margin: 50px auto 0;
    }
.adminPanel   .brand_direction  .brand_logo {
      margin-top: 50px;
      margin-bottom: 50px;
    } 
    .brand-text-white {
      font-size: 50px;
      font-weight: normal;
  }
.adminPanel   .about_top_banner .bannerbox {
      background: #00000040;
    }
.adminPanel   .contact_top_banner .bannerbox {
      box-shadow: 0px 22px 70px 4px #0000008F;
    }
.adminPanel   .about_top_banner .bannerbox h1,
    .contact_top_banner .bannerbox h1{
      font-size: 26px;
    }
.adminPanel   .contact_form_wrap .form-group.textareafld {
      padding: 0;
    }
.adminPanel   .about_content {
        margin: -180px auto 50px;
    }
.adminPanel   .social_media_page .what_we_do {
      margin-bottom: 50px;
    }
.adminPanel   .common_social_slider {
      padding-top: 100px;
    }  
    .font-18 {
      font-size: 16px;
      line-height: 23px;
    }
.adminPanel   .text_block {
      padding: 0 30px;
    }
.adminPanel   .envisioning {
      margin-top: 100px;
    }
.adminPanel   .social_media_page .home_gallery_block {
      padding-bottom: 0;
    }
.adminPanel   .social_media_page h6.workhead {
      display: block;  
    }
.adminPanel   .featured_work_slider  .swiper-slide.swiper-slide-active:hover, 
    .seamless_booking_slider_wrapper .swiper-slide.swiper-slide-active {
      transform: none;
    }
.adminPanel   .seamless_booking_slider_wrapper .swiper {
      padding: 0;
    }
.adminPanel   .seamless_booking_slider_wrapper {
      margin-right: -15px;
    }
.adminPanel   .featured_work_slider.more_project_slider {
      padding-bottom: 100px;
    }
.adminPanel   .follow_milkbar_top_inner {
      gap: 20px;
      padding: 0 30px;
    }
.adminPanel   .follow_media_grid {
      gap: 4px;
    }
.adminPanel   .project_list_wrapper.plr-100 {
      padding-left: 50px;
      padding-right: 50px;
    }
.adminPanel   .project_list_wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
.adminPanel   .project_list_wrapper .common_work_slide {
      margin-bottom: 0px;
    }
.adminPanel   .services_tabs_panel {
        padding-left: 0;
      }
.adminPanel     .services_tabs_panel > h6 {
          text-align: center;
          margin: 0 0 50px;
      }
.adminPanel     .service_tab_images, .service_tab_items {
          flex: 0 0 auto;
          width: 100%;
      }
.adminPanel     .service_tab_images {
          padding: 0;
      }
.adminPanel     .service_desc {
          display: flex;
          gap: 20px;
          max-width: 300px;
          transition: all ease 0.3s;
          opacity: 0;
          visibility: hidden;
          display: none;
      }
.adminPanel     .service_tab_images .swiper-slide {
        flex-direction: column;
        text-align: center;
        opacity: 0.5;
      }
.adminPanel     .service_tab_images .swiper-slide.swiper-slide-active {
        opacity: 1;
      }
.adminPanel     .service_tab_item .service-name {
        text-align: center;
        width: 100%;
        position: relative;
      }
.adminPanel     .service_tab_item .service-name:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #a2bb42;
        transition: all ease 0.3s;
        opacity: 0;
    }
.adminPanel   .swiper-slide-active  .service_tab_item .service-name:after {
        opacity: 1;
      }
.adminPanel     .service_tab_item .service-name h3 {
          font-size: 18px;
          line-height: 22px;
      }
.adminPanel     .mobile_service_content {
        width: 100%;
        padding: 20px 10px 30px;
        opacity: 0;
        visibility: hidden;
        transition: all ease 0.3s;
      }
.adminPanel     .mobile_tab_content:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
    }
.adminPanel   
      .swiper-slide-active .mobile_service_content {
        opacity: 1;
        visibility: visible;
      }
.adminPanel     .mobile_service_content .service-name h3 {
          opacity: 1;
      }
.adminPanel     .mobile_service_content .service_desc {
          display: block;
          opacity: 1;
          visibility: visible;
          margin: 0 auto;
      }
.adminPanel     .mobile_tab_content {
        width: 100%;
        height: 540px;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
.adminPanel     .services_tabs_panel .slider_nav {
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          width: 100%;
          padding: 0 15px;
          z-index: 9;
      }
.adminPanel     .services_tabs_panel .slider_nav .service-swiper-custom-scrollbar {
          flex: 0 auto;
          position: relative;
          height: 2px;
          background: #F5F5F54D;
      }
.adminPanel     .services_tabs_panel .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
          background: #ffffff;
      }
.adminPanel     .follow_milkbar_bottom .swiper-slide {
          opacity: 0.3;
      }
.adminPanel     .follow_milkbar_bottom .swiper-slide.swiper-slide-active {
        opacity: 1;
      }
.adminPanel     .follow_media_image img {
            width: 100%;
      }
.adminPanel     .footer_wrapper {
          padding: 0 50px;
            gap: 0;
          flex-direction: column;
      }
.adminPanel     .footer_menu.reach_us ul {
          display: inline-flex;
          margin: 0 0 50px;
          border-bottom: 1px solid #ffffff69;
          padding: 0 0 50px;
      }
    }

  @media(max-width: 645px){
    .bannerbox {
      max-width: 270px;
      margin-top: 30px;
      height: calc(100% - 238px);
    }
.adminPanel   .main_home_banner picture img,
    .inner_page_banner picture img {
      height: auto;
    }
.adminPanel   .what_we_do {
      margin-top: -161px;
    }
.adminPanel   .option_types_radios input[type=radio] + label {
      padding: 16px 25px 15px;
    }
.adminPanel   .project_verticle_images {
      grid-template-columns: repeat(1, 1fr);
    }
.adminPanel   .project_verticle_images .project_verticle_image {
      max-width: 430px;
      width: 100%;
    }
.adminPanel   .project_verticle_images .project_verticle_image:last-child {
      margin-top: 50px;
      margin-left: auto;
    }
.adminPanel   .project_verticle_images .project_verticle_image:last-child img {
      margin-left: auto;
    }
}

  @media(max-width: 575px){
    .home_gallery_top .home_gallery_image.gallery_image_1 {
      width: 208px;
      height: 292px;
    }
.adminPanel   .gallery_image_2 {
      max-width: 185px;
      height: 229px;
    }
.adminPanel   .gallery_image_3 {
      max-width: 247px;
      height: 187px;
      margin-top: 183px;
    }
.adminPanel   .gallery_image_4 {
      width: 100px;
      height: 140px;
      margin-left: 75px;
    }
.adminPanel   .home_gallery_middle {
      margin-top: -145px;
    }
.adminPanel   .workbox_head {
      margin: 00px;
    }
.adminPanel   .common_social_slider {
      flex-direction: column-reverse;
    }
.adminPanel   .common_social_slider .swiper-slide:not(.swiper-slide-active) .social_slider_slide {
      height: calc(500px - 40px);
      margin-top: 40px;
      transition: all ease 0.3s;
    }
.adminPanel   .common_work_slide h2 {
      font-size: 26px;
    }
.adminPanel   .bannerbox {
      max-width: 270px;
    }
.adminPanel   .social_ltrslider_outer .social_ltrslider_left, .social_rtlslider_outer .social_rtlslider_left {
      padding: 20px 30px 0;
      text-align: center;
    }
.adminPanel   .common_social_slider .slider_scrollbar.swiper-scrollbar-horizontal {
      margin-left: auto;
      margin-right: auto;
    }
.adminPanel   .common_social_slider h3 {
      font-size: 26px;
    }
.adminPanel   .common_social_slider p {
      font-size: 16px;
      line-height: 23px;
    }
.adminPanel   .social_ltrslider_outer.common_social_slider1 {
      padding-top: 40px;
    }
.adminPanel   .common_social_slider > div {
      flex: 0 0 100%;
      max-width: 100%;
    }
.adminPanel   .option_types_radios input[type=checkbox] + label {
        padding: 17px 25px 17px;
    }
.adminPanel   .project_list_wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
.adminPanel   .project_list_wrapper.plr-100 {
      padding-left: 20px;
      padding-right: 20px;
    }
}
  @media(max-width: 480px){
    .common_service_slide_text:after {
        background: #000000;
        opacity: 50%;
    }
.adminPanel   .services_slider .slider_nav {
      margin-top: -60px;
      position: relative;
      z-index: 1;
      padding: 0 15px;
    }
.adminPanel   .home_banner_bottom {
      padding: 0;
    }
.adminPanel   .home_banner_bottom h1{
      font-size: 23px;
    }
.adminPanel   .common_social_slider {
      flex-direction: column-reverse;
    }  
  }
@media(max-width: 375px){
    header > nav > ul > li:not(:last-child) {
      margin-right: 24px;
    }
.adminPanel   .home_banner_bottom {
      margin: 0 -45px;
    }
.adminPanel   .home_banner_bottom h1 {
      font-size: 22px;
    }
}
@media(max-width: 359px){
    .home_banner_bottom {
      margin: 0 -15px;
    }
.adminPanel   header > nav > ul > li:not(:last-child) {
      margin-right: 18px;
    }
}