*{
  box-sizing: border-box;
}
:root{
  --body-font-family: 'Athelas';
  --mainheading-font-family: 'Houstiq';
  --heading-font-family: 'Engravers\' Gothic';
  --flawlush-font-family: 'flawlushregular';
  --magonda-font-family: 'Magonda';
  --font-spacing: 2px;
  --font-wight-500: 500;
  --body-color: #000;
  --color-light: #fff;
  --color-light-gray: #EFEDE6;
  --color-dark-green: #282E29;
  --font-size-28: 28px;
  --font-size-18: 18px;
  --body-font-size: 80;  
  --wrapper-width: calc(min(calc(var(--body-font-size) * 16px), 100vw));  
}
body{
  margin: 0;
  padding: 0;
  background: var(--color-light-gray);
  font-family: var(--body-font-family);
  font-size: 18px;
  line-height: 28px;
  color: var(--body-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img{
  max-width: 100%; 
}
img,
video{
  vertical-align: bottom;
}
a{
  color: var(--body-color);
  text-decoration: none;
}
p{
  margin: 0 0 30px;
}
p:last-child{
  margin: 0;
}

/*---- Heading ----*/
h1, h2, h3, h4, h5, h6{
  margin: 0 0 20px;
  font-family: var(--heading-font-family);
  font-weight: var(--font-wight-500);
  line-height: 1.05;  
  letter-spacing: var(--font-spacing);
  text-transform: uppercase;    
}
h1{
  font-size: 48px;  
  font-family: var(--mainheading-font-family);
  letter-spacing: 0;
}
h2{
  font-size: 48px;  
  font-family: var(--mainheading-font-family);
  letter-spacing: 0;
}
h3{
  font-size: 28px;
}
h4{
  font-size: 24px;
}
h5{
  font-size: 18px;
}
h6{
  font-size: 14px;
}
  
/*---- Color ----*/
.color-light,
.color-light a,
.color-light button{
  color: var(--color-light);
}
.color-dark{
  color: var(--body-color);
}

/*---- Font ----*/
.font-35{
  font-size: 35px;
  line-height: 1.2;
}

/*---- Flexbox ----*/
.d-flex{
  display: flex;
  flex-wrap: wrap;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-between{
  justify-content: space-between;
}
.justify-content-end{
  justify-content: flex-end;
}
.align-items-center{
  align-items: center;
}
.align-items-start{
  align-items: flex-start;
}
.align-items-end{
  align-items: flex-end;
}
.flex-column{
  flex-direction: column;
}
.column-3{
  column-gap: 30px;
}
.column-3 > div{
  flex: 1;
}

/*---- Grid ----*/
.d-grid{
  display: grid;
}

/*---- Background ----*/
.bg-black{
  background: var(--body-color);
}
.bg-cover{
  background-repeat: no-repeat;  
  background-size: cover;
  background-position: center center;
}

/*---- Position ----*/
.position-fixed{
  position: fixed;
}
.position-absolute{
  position: absolute;
}
.position-relative{
  position: relative;
}
.position-sticky{
  position: sticky;
}
.top-0{
  top: 0;
}

/*---- Blocks ----*/
.image-block img{
  width: 100%;
}
.video-block video{
  width: 100%;
  height: 100%;
}
.text-block{
  font-size: 14px;
  line-height: 22px;
}
.richtext-block{
  max-width: 800px;  
}

/*---- Margin ----*/
.mb-30{
  margin-bottom: 30px;
}

/*---- Padding ----*/
.py-220{
  padding-top: 220px;
  padding-bottom: 220px;
}
.py-180{
  padding-top: 180px;
  padding-bottom: 180px;
}
.py-150{
  padding-top: 150px;
  padding-bottom: 150px;
}
.py-120{
  padding-top: 120px;
  padding-bottom: 120px;
}
.py-100{
  padding-top: 100px;
  padding-bottom: 100px;
}
.py-80{
  padding-top: 80px;
  padding-bottom: 80px; 
}
.py-50{
  padding-top: 50px;
  padding-bottom: 50px; 
}
.py-40{
  padding-top: 40px;
  padding-bottom: 40px; 
}
.py-20{
  padding-top: 20px;
  padding-bottom: 20px; 
}
.pt-220{
  padding-top: 220px;
}
.pt-200{
  padding-top: 200px;
}
.pt-180{
  padding-top: 180px;
}
.pt-150{
  padding-top: 150px;
}
.pt-100{
  padding-top: 100px;
}
.pt-90{
  padding-top: 90px;
}
.pt-80{
  padding-top: 80px;
}
.pt-60{
  padding-top: 60px;
}
.pt-50{
  padding-top: 50px;
}
.pt-40{
  padding-top: 40px;
}
.pt-20{
  padding-top: 20px;
}
.pt-10{
  padding-top: 10px;
}
.pb-150{
  padding-bottom: 150px;
}
.pb-100{
  padding-bottom: 100px;
}
.pb-90{
  padding-bottom: 90px;
}
.pb-80{
  padding-bottom: 80px;
}
.pb-60{
  padding-bottom: 60px;
}
.pb-50{
  padding-bottom: 50px;
}
.pb-40{
  padding-bottom: 40px;
}
.pb-30{
  padding-bottom: 30px;
}
.pb-20{
  padding-bottom: 20px;
}
.pb-10{
  padding-bottom: 10px;
}

/*---- Margin ----*/
.mb-0{
  margin-bottom: 0;
}
.m-auto{
  margin-left: auto;
  margin-right: auto;
}
.mt-auto{
  margin-top: auto;
}
.mb-100{
  margin-bottom: 100px;
}

/*---- Common Classes ----*/
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
.full-screen{
  height: 100vh;
}
.container{
  width: 100%;
  max-width: var(--wrapper-width);
  margin: 0 auto;
  padding: 0 20px;
}
.container-500{
  max-width: 500px;  
}
.center{
  text-align: center;
}
.end{
  text-align: right;
}
.list-style-none{
  margin: 0;
  padding: 0;
  list-style: none;
}
.divider{
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}
.color-light .divider{
  border-bottom-color: rgb(255,255,255,0.5);
}  
.top-bottom-equal p{
  margin: 0;
}
.text-no-wrap{
  white-space: nowrap;
}
.no-wrap{
  flex-wrap: nowrap
}
.flawlush-font{
  font-family: var(--flawlush-font-family);
  text-transform: none;
  letter-spacing: -2px;
}
.title-block{
  font-family: var(--heading-font-family);
  letter-spacing: 2px;
  text-transform: uppercase;
}
.cursive-title-block{
  font-family: var(--flawlush-font-family); 
  letter-spacing: -4px;
  text-transform: none;
}
.mangonda-title-block{
  font-family: var(--magonda-font-family);
}
.d-block{
  display: block
}

/*---- Header ----*/
.site-header{  
  padding: 45px 0 0;
  z-index: 99;  
}
.site-header:before{
  width: 100%;
  height: 250%;
  background-image: linear-gradient(rgb(0,0,0), transparent);
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
  z-index: -1;
  pointer-events: none;
  transition: all ease 0.2s;
}
.site-nav{
  border-bottom: 1px solid rgb(255,255,255,0.5);
}
.site-nav ul{
  margin: 0;
  padding: 0;
  list-style: none;
  column-gap: 75px;
}
.site-nav li a{
  padding: 0 0 30px;
  display: block;  
  font-family: var(--heading-font-family);
  font-size: 12px;
  color:#fff;  
  text-transform: uppercase;
  letter-spacing: var(--font-spacing);
}
.site-nav li:last-child a{
  font-family: var(--magonda-font-family);
  font-size: 35px;  
  text-transform: none;
}  
.mobile-head{
  display: none;
}
.close-menu{
  display: none;
}

/*---- Hero Banner ----*/
.hero-section{
  height: 100vh;
}
.container-hero-section{
  height: calc(100vh - 30px);  
}
.hero-banner{
  width: 100%;
  height: 100%;
}
.hero-banner img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.hero-banner video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-section .hero-summary{
  width: calc(100% - 30px);  
  max-width: 400px;
  z-index: 2;
}
.hero-section .container-summary{
  max-width: 500px;
  padding: 50px 40px 40px;
  bottom: -30px;
  background: rgb(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(5px);
}
.hero-section .container-summary-wrapper{
  max-width: calc(500px - 200px);
}
.hero-section.bottom-150 .hero-summary{
  bottom: 150px;
}
.hero-section.bottom-100 .hero-summary{
  bottom: 100px;
}
.hero-section.bottom-30 .hero-summary{
  bottom: 30px;  
}
.hero-section .top-summary{  
  display: grid;
  row-gap: 30px;
  top: -5.2%;    
}
.hero-section .top-summary .logo-block{
  max-width: 60px;
}
.hero-section .cursive-title-block{
  font-size: 25px;
  text-transform: none;
}
.hero-section .text-left{  
  max-width: 320px;
  width: 100%;
  top: 12%;
  left: -8%;
  text-shadow: 0 0 2px rgb(0,0,0,1);  
}
.hero-section .text-right{
  top: 30%;
  right: -5%;  
}
.hero-section .logo-block{
  max-width: 20px;
  margin: 0 auto;
}
.hero-section .logo-block.top,
.hero-section .logo-block.top{
  max-width: 40px;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-55%);
}
.top-bottom-overlay:before,
.top-bottom-overlay:after,
.bottom-overlay:after,
.bottom-overlay-dark:after{
  width: 100%;
  display: block;
  content: "";
  position: absolute;
  left: 0;  
  pointer-events: none;
  z-index: 1;
}
.top-bottom-overlay:before{
  height: 200px;
  background-image: linear-gradient(rgb(0,0,0), transparent); 
  top: 0;
  opacity: 0.6;
}
.top-bottom-overlay:after{
  height: 200px;    
  background-image: linear-gradient(transparent, rgb(0,0,0));
  bottom: 0;
  opacity: 0.2;
}
.bottom-overlay-dark:after{
  height: 60%;
  background-image: linear-gradient(transparent, rgb(0,0,0));
  opacity: 0.7;
  bottom: 0;
}

/*---- Image overlap sidetext ----*/
.image-overlap-sidetext-section .title-block{
  width: 80%;
}
.image-overlap-sidetext-section .text{
  max-width: 270px;
  width: 100%;  
  font-size: 28px;
  line-height: 25px;
  text-shadow: 1px 0px 1px #fff;  
  top: 30%;  
}
.image-overlap-sidetext-section .text-left{
  left: 40px;
  transform: translateX(-100%);
}
.image-overlap-sidetext-section .text-right{
  right: 15px;
  transform: translateX(100%);
}
.single-venue .image-overlap-sidetext-section .text-left{
  max-width: 320px;
  top: 10%;
  left: 10px;
}
.single-venue .image-overlap-sidetext-section .text-right{
  top: unset;
  bottom: 10%;
  right: -20px;
}
.image-overlap-sidetext-section .bottom-text{
  max-width: 80%;  
  column-gap: 60px;
}  
.image-overlap-sidetext-section .bottom-text .title-block{
  width: 100%;
}
.image-overlap-sidetext-section .bottom-text .text-item{
  padding: 0 60px 0 0; 
  flex: 1;
  border-right: 1px solid rgb(0,0,0,0.5);
}
.image-overlap-sidetext-section .bottom-text .text-item:last-child{
  padding: 0;
  border: none;
}

/*---- Title with line ----*/
.title-with-line{
  border-bottom: 1px solid rgb(0,0,0,0.2);  
}
.title-with-line h3{
  margin: 0;  
  text-transform: none;
  transform: translateY(12px);  
}
.title-with-line-wrapper{
  padding: 0 160px;
  font-family: var(--flawlush-font-family);
  font-size: var(--font-size-28);  
}

/*---- Tab section scroll ----*/
.tab-section .image-block,
.tab-section .video-block{
  height: calc(100vh - 150px);
}
.tab-section .image-block img,
.tab-section .video-block video{
  height: 100%;
  object-fit: cover;
}
.tab-section-scroll{
  padding: 50px 0 40px;
}
.tab-section-scroll ul{
  column-gap: 50px;
}
.tab-section-scroll li a{  
  padding: 0 0 10px;
  display: block;
  font-family: var(--heading-font-family);  
  font-size: 16px;
  color: var(--body-color);
  letter-spacing: 2px;
  text-transform: uppercase;  
  position: relative;
}
.tab-section-scroll li a:after{
  width: 100%;
  height: 1px;  
  background: rgb(0,0,0,0.5); 
  display: block;
  content: "";
  position: absolute;
  bottom: 0; 
  visibility: hidden;
  opacity: 0;
}
.tab-section-scroll li a.active:after{
  visibility: visible;
  opacity: 1;
}
.services-hero-section{
  padding-bottom: 160px;
  background-image: url('../images/services-hero-banner.jpg');
}
.services-hero-section:before{
  opacity: 0;
}
.services-hero-section:after{
  opacity: 0.7;
}

/*---- Richtext ----*/
.richtext .container{
  max-width: 900px;
}
.richtext .text-block{
  font-size: var(--font-size-18);
  line-height: 28px;
}

/*---- Image Banner ----*/
.image-banner .container{
  max-width: 1000px;
}
.image-banner .text-block{
  font-size: var(--font-size-28);
  line-height: 34px;
}

/*---- Tab section ----*/
.tab-nav{
  column-gap: 60px;
}
.tab-nav button{
  padding: 0 0 15px;
  display: inline-block;
  background: none;
  border: none;
  border-bottom: 1px solid transparent;
  font-family: var(--heading-font-family);  
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
  cursor: pointer;
}
.tab-nav button.active{
  border-bottom-color: #fff; 
}
.tab-section-wrapper{
  padding: 0 100px;
}
.tab-panel .text{
  top: 10%;
  left: 60px;
  transform: translateX(-100%);
}
.tab-panel .text-block{
  max-width: 600px;
  width: 100%;
  text-align: right;
  bottom: 8%;
  right: -80px;  
}

/*---- Grid items ----*/
.grid-row .image-block{
  height: 320px;  
  padding: 0 0 35px;
}
.grid-row .image-block img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.grid-row.pb-0 .image-block{
  padding: 0;
}
.grid-item .title-block{
  padding: 0 0 20px;
  font-size: 16px;
} 
.grid-item .logo-block{
  padding: 0 0 10px;
}
.grid-item .organizer{
  font-size: 25px;
  line-height: 35px;
}
.grid-item .location{
  padding: 0;
  font-size: 14px;
}
.grid-item .summary-wrapper{
  max-width: 300px;
  margin: 0 auto;
}
.events-section .divider{
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  border: none;  
}
.events-section .divider:before,
.events-section .divider:after{
  width: calc(50% - 30px);
  height: 1px;
  display: block;
  content: "";
  background: rgb(0,0,0,0.5);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.events-section .divider:before{
  left: 0;
}
.events-section .divider:after{
  right: 0;  
}
.events-section .container-summary{
  padding: 50px 0 40px;
}
.events-section .container-summary .divider:before,
.events-section .container-summary .divider:after{
  background: rgb(255,255,255,0.5);
}
.venues-section .divider{
  margin-top: 8px;
}
.venues-section .venue-items .divider{
  width: 70%;
  margin-left: auto;
  margin-right: auto;  
}
.featured-venues .title-block{
  top: 80px;
  z-index: 2;  
}
.grid-row .swiper-slide{
  height: auto;
}
.grid-row.equal-height .swiper-slide *{
  width: 100%;
  height: 100%;
}
.event-listing,
.venue-listing{
  padding: 0 70px;
}
.event-item,
.venue-item{
  height: auto
}
.event-index .grid-item,
.venue-index .grid-item{
  margin-bottom: 180px;  
}

/*---- Venue index ----*/
.venue-hero-section.hero-section .hero-summary{
  max-width: 500px;  
}

/*---- Event index ----*/
.event-index .events-section .divider{
  margin-bottom: 20px;
}
.event-hero-section.hero-section .hero-summary{
  max-width: 500px;  
}

/*---- Location ----*/
.location-wrapper{
  max-width: 580px;
}
.location-items .title-block{
  padding-bottom: 10px;
}

/*---- Homepage ----*/
.home-hero-section .image-block{
  max-width: 150px;
}
.home-hero-section .title-block{
  padding: 0 0 10px;
}
.home-hero-section .nav-block{
  bottom: 100px;
  z-index: 2;
}
.home-hero-section .nav-block li a{
  font-family: var(--heading-font-family);
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: var(--font-spacing);
}  
.home-hero-section .nav-block li:last-child a{
  opacity: 0.5;
}
.home-hero-section:after{
  opacity: 1;
}

/*---- Filters ----*/
.filter-section{  
  transition: all ease 0.2s;
}
.filter-section.sticky{
  background: rgb(0,0,0,0.25);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(5px);
  z-index: 999;
}
.filter-section.sticky .filter-item .p-dropdown-label{
  color: #fff;
}
.filter-section.sticky .filter-item .p-dropdown-trigger path{
  color: #fff;
}
.filter-items{
  column-gap: 45px;
}
.filter-item .p-dropdown{
  background: none;
  border: none;  
  border-bottom: 1px solid transparent;
  box-shadow: none;    
  border-radius: 0;
}
.filter-item .p-dropdown-label{
  padding: 8px 10px 8px 0;
  font-family: var(--heading-font-family);
  font-size: 14px;
  color: var(--body-color);
  text-transform: uppercase;
  letter-spacing: 2px;
}
.filter-item .p-dropdown-trigger{
  width: 11px;  
}
.filter-item .p-dropdown-trigger path{
  color: var(--body-color);
}
.p-dropdown-panel{
  width: 140px;
  border-radius: 0;
  box-shadow: none;
}
.p-dropdown-items{
  margin: 0;
  padding: 5px 0;
}
.p-dropdown-items .p-dropdown-item,
.p-dropdown-items .p-dropdown-item.p-focus{
  padding: 4px 20px;
  background: none;
  font-family: var(--heading-font-family);
  font-size: 14px;  
  color: rgb(0,0,0,0.8);
  text-transform: uppercase;
  letter-spacing: 2px;
}
.p-dropdown-items .p-dropdown-item:hover,
.p-dropdown-items .p-dropdown-item.p-focus{
  color: var(--body-color);
}

/*---- Image column ----*/
.image-column-section .grid-row{  
  grid-template-columns: 30% auto;
  align-items: center;
  column-gap: 100px;
}
.image-column-section img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.image-column-section .text-right{
  bottom: 12%;
  right: -16%;
  transform: rotate(-2deg);
}
.image-column-section .text-left{
  bottom: 12%;
  left: -36%;
  transform: rotate(-2deg);
}
.image-column-section .cursive-title-block{
  font-size: 35px;
  line-height: 45px;  
}
.image-column-section .icon-text-list{
  width: 100%;
  max-width: 200px;
  height: 100%;
  row-gap: 50px;  
  top: 0;
  left: -40px;
}
#venue-filter-section .filter-items.filterDisable {
  pointer-events: none;
  opacity: 0.5;
}
.image-column-section .icon-text-item{
  width: 100%;  
  border-bottom: 1px solid rgb(255,255,255,0.5);
  display: grid;
  row-gap: 20px;
}
.image-column-section .icon-text-item img{
  width: 60px;  
}
.image-column-section .icon-text-list .title-block{
  padding-bottom: 10px;
  font-size: 14px;  
}
.image-column-section .cta-block{
  bottom: 5%;
}
.image-column-section .cta-block img{
  width: 18px;
}

/*---- CTA ----*/
.cta-block{
  font-family: var(--magonda-font-family);
  font-size: 35px;
  letter-spacing: -2px;
}
.cta-text{
  max-width: 250px;  
  border-bottom: 1px solid #000;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
} 
.cta-block a{
  display: flex;
  align-items: center;
  column-gap: 30px;
  text-stroke: 1px;
  -webkit-text-stroke: 1px;  
}

/*---- Locations ----*/
.map-wrapper{
  width: 95%;
  max-width: 600px;  
  padding: 0 100px;
}
.map-summary{
  row-gap: 115px;
  bottom: 15%;
  right: -10%;  
}
.map-info{
  max-width: 250px;
  padding: 15px 20px;
  background: rgb(255,255,255,0.8);
  box-shadow: 0 0 20px rgb(0,0,0,0.1);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);  
}
.map-organizer{
  font-family: var(--magonda-font-family);
  font-size: 34px;
  text-shadow: 0 0 2px #fff;
  transform: translateX(62%);    
}

/*---- About ----*/
.template-about .image-overlap-sidetext-section .text{
  max-width: 295px;
}

/*---- Get In Touch ----*/
.get-in-touch .summary-block{
  font-size: 24px;
  line-height: 28px;
}

/*---- Contact ----*/
.contact-wrapper{
  grid-template-columns: 1fr 1fr;
}
.contact-left{
  padding: 200px 100px 100px;
  flex-wrap: nowrap;
  z-index: 1;
}
.contact-left .text-top{
  max-width: 300px;
  font-size: var(--font-size-18);
  line-height: 22px;
}
.contact-left .text-bottom{
  margin-top: auto;  
}
.contact-left ul{
  row-gap: 30px;
}
.contact-left li{
  padding: 0 0 0 40px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 16px;
  position: relative;
}
.contact-left li svg{
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
}
.contact-left li:nth-child(1) svg{
  width: 18px;
  height: 18px;
  left: 2px;
}
.contact-left li:nth-child(2) svg{
  width: 28px;
  height: 28px;
  left: -3px;
}
.contact-left:after{
  z-index: -1;
}
.contact-right{
  padding: 200px 100px 100px;
  background: rgb(255,255,255,1);  
}
.office-address{
  max-width: 210px;
  margin: 40px 0 0;
  padding: 30px 0 0;
  border-top: 1px solid rgb(255,255,255,0.5);
  row-gap: 10px;  
}

/*---- Input ----*/
.form-field{
  padding: 0 0 35px;
}
.form-field input,
.form-field textarea{
  width: 100%;
  height: 50px;
  background: none;
  border: none;
  border-bottom: 1px solid rgb(0,0,0,0.5);
  font-family: var(--body-font-family);
  font-size: 16px;
  color: #000;
}
.form-field input:focus,
.form-field textarea:focus{
  box-shadow: none;
  outline: none;
}
.form-field textarea{
  height: 200px;
  padding-top: 20px;
}
.submit-field .submit-button{  
  height: 40px;
  padding: 0 10px;
  background: none;
  border: none;
  border-bottom: 1px solid #000;
  font-family: var(--body-font-family);
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
}
::-webkit-input-placeholder{
  color: #000;
  opacity: 1;
}
::-moz-placeholder{
  color: #000;
  opacity: 1;
}
:-ms-input-placeholder{
  color: #000;
  opacity: 1;
}
:-moz-placeholder{
  color: #000;
  opacity: 1;
}

/*---- Footer ----*/
.site-footer{  
  background: var(--color-dark-green);
  grid-template-columns: 45% auto;  
}
.footer-left{
  height: 60vh;
  background-image: url('../images/footer-bg.jpg');
  font-family: var(--mainheading-font-family);
  font-size: 38px;
  text-transform: uppercase;
}
.footer-right{
  padding: 0 0 0 170px;
}
.footer-right-wrapper{
  max-width: 250px;
}
.footer-widget:not(:last-child){
  padding-bottom: 60px;
}
.footer-right h6{
  padding: 0 0 20px;
  border-bottom: 1px solid rgb(255,255,255,0.5);
}
.site-footer .nav-widget li a{
  font-family: var(--magonda-font-family);
  font-size: 35px;  
}
.footer-logo{
  max-width: 95px;
  place-self: center;
  left: -28px;
}
.footer-bottom ul{
  margin: 0;
  padding: 0;
  list-style: none;
  column-gap: 30px;
}
.footer-bottom li{
  position: relative;
}
.footer-bottom li a{
  font-family: var(--heading-font-family);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.footer-bottom li:not(:first-child):before{
  width: 3px;
  height: 3px;
  background: #fff;
  border-radius: 50%;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(calc(-50% + 1px));
}

/*---- Responsive ----*/
@media(max-width: 1599px){

  :root{
    --body-font-size: 72;
  }

}

@media(max-width: 1440px){

  :root{
    --body-font-size: 68;
  }

}

@media(max-width: 1366px){

  :root{
    --body-font-size: 68;
  }
  .image-column-section .text-left{    
    left: -20%;    
  }
  .contact-left,
  .contact-right{
    padding-top: 140px;
  } 
  .form-field textarea{
    height: 120px;
    padding-top: 0;    
  } 

}

@media(max-width: 1280px){

  :root{
    --body-font-size: 60;
  }
  .image-column-section .icon-text-list{
    row-gap: 40px;
  }
  .image-column-section .icon-text-item img{
    width: 40px;
  }
  .image-column-section .icon-text-item{
    row-gap: 10px;
  }  
  .image-column-section .cursive-title-block{
    font-size: 30px;
  }

}

@media(max-width: 1080px){

  :root{
    --body-font-size: 55;
  }

}

@media(max-width: 992px){

  .m-px-0{
    padding-left: 0;
    padding-right: 0;
  }  
  .m-px-20{
    padding-left: 20px;
    padding-right: 20px;
  }
  .m-py-80{
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .m-py-50{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .m-py-30{
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .m-py-20{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .m-py-10{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .m-pt-120{
    padding-top: 120px;
  }
  .m-pt-100{
    padding-top: 100px;
  }
  .m-pt-70{
    padding-top: 70px;
  }
  .m-pt-50{
    padding-top: 50px;
  }
  .m-pt-40{
    padding-top: 40px;
  }
  .m-pt-10{
    padding-top: 10px;
  }
  .m-pb-120{
    padding-bottom: 120px;
  }
  .m-pb-100{
    padding-bottom: 100px;
  }
  .m-pb-80{
    padding-bottom: 80px;
  }
  .m-pb-70{
    padding-bottom: 70px;
  }
  .m-pb-60{
    padding-bottom: 60px;
  }
  .m-pb-30{
    padding-bottom: 30px;
  }
  .m-pb-10{
    padding-bottom: 10px;
  }
  .m-pb-0{
    padding-bottom: 0;
  }   
  .m-full-screen-auto{
    height: auto
  }
  .site-header{
    padding: 15px 0 0;    
    position: fixed;
  }
  .site-header:before{
    height: 112%;
  }
  .header-overlay:before{    
    opacity: 0.9;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
  .site-nav{
    width: 100%;
    height: 100%;
    padding: 60px 30px 0;
    background: var(--color-light-gray);
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: all ease 0.2s;
    z-index: 99;
  }  
  .site-nav.open-menu{
    transform: translateX(0%);
  }
  .site-nav ul{
    flex-direction: column    
  }
  .site-nav li:not(:last-child){
    margin: 0 0 15px;
  }
  .site-nav li a{
    padding: 0;
    font-size: 14px;
    color: var(--body-color);
  }
  .mobile-head{
    display: flex;
  }
  .mobile-head a{
    color: #fff;
  }
  .mobile-head .right-block{
    column-gap: 30px;
  }
  .mobile-head .toggle-button{
    padding: 12px 0 12px 30px;
    border-left: 1px solid #fff;
  }
  .close-menu{
    width: 25px;
    height: 25px;
    top: 20px;
    right: 20px;
    display: block;    
  }
  .close-menu svg{
    width: 100%;
    height: 100%;
  }
  .home-hero-section .nav-block{
    bottom: 40px;  
  }
  .image-overlap-sidetext-section .title-block{
    width: calc(100% - 20px);
    padding-bottom: 20px;
  }
  .image-overlap-sidetext-section .text-left{    
    max-width: 200px;
    top: 100px;    
    left: -12px;
    transform: none;
  }
  .image-overlap-sidetext-section .text-right{    
    max-width: 220px;
    top: unset;
    bottom: -50px;
    right: -15px;
    transform: none;
  }
  .image-column-section .grid-row{
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 40px;
  }
  .image-column-section .grid-small img{
    max-width: 80%;
    margin: 0 auto;
  }  
  .image-column-section .grid-large:after{
    width: 100%;
    height: 50%;
    background-image: linear-gradient(transparent, rgb(0,0,0));
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;    
  }
  .image-column-section .icon-text-list{
    left: 40px;
    z-index: 1;
  }
  .image-column-section .cta-block{
    position: static;
  }
  .image-column-section .text-right {    
    right: 5%;    
  }
  .single-venue .image-column-section .grid-large:after,
  .single-event .image-column-section .grid-large:after{
    display: none;
  }
  .single-venue .image-column-section,
  .single-event .image-column-section{
    padding-bottom: 50px;
  }
  .featured-events .title-block{
    padding-bottom: 10px;
  }
  .event-listing, 
  .venue-listing{
    padding: 0;
  }
  .hero-section .text-right{
    right: unset;
    left: -10px;
  }
  .hero-section.bottom-100 .hero-summary,
  .hero-section.bottom-150 .hero-summary{
    bottom: 40px;
  }
  .venue-items-wrapper .container{
    padding: 0;
  }
  .title-with-line-wrapper{
    padding: 0 0 0 0;
  }
  .template-about .image-overlap-sidetext-section .text-right{
    bottom: -30px;
    right: 0;
    text-align: right;
  }
  .page-template,
  .template-index{
    padding-top: 110px;
  }
  .template-index .grid-item:first-child{
    height: calc(100vh - 288px)
  }
  .event-index .grid-item:first-child{
    height: calc(100vh - 363px)
  }
  .template-index .grid-item{
    height: calc(100vh - 138px)
  }
  .event-item .text,
  .venue-item .text{
    display: none;
  }
  .event-index .grid-item,
  .venue-index .grid-item{
    margin-bottom: 100px;
  }
  .hero-section .top-summary{
    position: static;
  }
  .event-item, 
  .venue-item{
    height: calc(100vh - 30px);
  }
  .hero-section .hero-summary{
    row-gap: 40px;
  }
  .single-venue .image-overlap-sidetext-section .image-block{
    padding-bottom: 48px;
  }
  .single-venue .image-overlap-sidetext-section .text-left{
    max-width: 350px;
    left: -10px;
    text-shadow: 1px 0 1px #fff;
  }
  .single-venue .image-overlap-sidetext-section .text-right {    
    max-width: 300px;
    bottom: 0;
  }
  .image-overlap-sidetext-section .bottom-text .title-block{
    padding: 0 0 10px;
  }
  .location-wrapper{
    padding: 0 25px;
  }
  .filter-items{
    padding-bottom: 1px;
  }
  .tab-section-wrapper{
    padding: 0 10px;
  }
  .tab-nav{
    column-gap: 40px;
    flex-wrap: nowrap;    
    overflow-x: auto;
  }
  .tab-nav button{
    padding: 0 0 10px;
  }
  .tab-panel .text{
    left: -15px;
    transform: none;
  }
  .tab-panel .text-block{
    position: static;
    transform: translate(15px, -65px);
  }    
  .contact-wrapper{
    grid-template-columns: 1fr;
  }
  .contact-left{
    padding-top: 180px;
  }
  .contact-right{
    padding: 100px;
  }
  .site-footer{
    grid-template-columns: 1fr;
  }
  .footer-left{
    height: 60vh;
    padding: 0 0 80px;
    align-items: flex-end;
  }
  .footer-logo{
    max-width: 55px;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
  .footer-right{
    padding: 120px 40px 60px;
  }

}

@media(max-width: 767px){

  body{
    font-size: 16px;
    line-height: 26px;
  }
  .tab-nav{
    column-gap: 30px;
    justify-content: flex-start;
  }
  .tab-section .image-block, 
  .tab-section .video-block{
    height: 450px;
  }
  .image-banner{
    height: 70vh;
  }
  .image-banner img{
    height: 100%;
    object-fit: cover;
  }
  .image-banner .text-block{
    font-size: 18px;
    line-height: 26px;
  }
  .contact-left{
    padding: 150px 30px 30px;
    background-position: 15% 50%;
  }  
  .contact-left:after{
    height: 50%;
  }
  .contact-right{
    padding: 40px 30px;
  }
  .form-field textarea{
    height: 160px;
  }   
  .contact-right .text-block{
    font-size: 18px;
    line-height: 25px;
  }

}

@media(max-width: 575px){

  h1{
    font-size: 42px;
  }
  h2{
    font-size: 38px;
  }
  .image-column-section .text-left{
    left: -12%;
  }
  .image-column-section{
    padding-bottom: 40vh;
  }  
  .image-column-section .icon-text-list{
    top: 50%;
  }
  .filter-section .container{
    padding: 0 10px;
  } 
  .filter-items{
    column-gap: 10px;
  }
  .filter-item .p-dropdown-label{
    font-size: 12px;
  }
  .filter-item .p-dropdown-label{
    padding: 8px 4px 8px 0;
  }
  .image-overlap-sidetext-section .bottom-text{
    max-width: 100%;
    column-gap: 20px;
  }
  .image-overlap-sidetext-section .bottom-text .text-item{
    padding: 0 20px 0 0;
  }
  .map-wrapper{    
    width: 100%;
    padding-left: 0;
    padding-right: 60px;
  } 
  .map-summary{
    right: -20%;
    row-gap: 70px;
  }
  .map-info{
    font-size: 14px;
    line-height: 20px;
  }
  .map-organizer{        
    font-size: 23px;
    transform: translateX(8%);
  } 

}

@media(max-width: 375px){

  h1{
    font-size: 36px;
  }
  h2{
    font-size: 30px;
  }
  .image-overlap-sidetext-section .title-block{
    width: 100%;
  }
  .grid-item .organizer{
    font-size: 30px;
  }  
  .image-column-section .text-left{
    left: 15px;
  }
  .image-column-section .text-right{
    right: unset;
    left: 15px;
  }
  .image-column-section .cursive-title-block{
    font-size: 28px;
  }
  .tab-section-scroll ul{
    column-gap: 35px;
  }
  .image-column-section{
    padding-bottom: 45vh;
  }  
  .image-column-section .icon-text-list{
    top: 65%;
  }
  .image-overlap-sidetext-section .bottom-text{   
    column-gap: 10px;
  }
  .image-overlap-sidetext-section .text{
    font-size: 26px;
  }
  .image-overlap-sidetext-section .bottom-text .text-item{
    padding: 0 10px 0 0;
  }
  .image-overlap-sidetext-section .bottom-text .title-block{
    padding: 0 0 5px;
    font-size: 15px;
  }   
  .footer-left{
    font-size: 30px;
  }

}

@media(max-width: 320px){

  .title-block span.d-block{
    display: inline;
  }
  .filter-item .p-dropdown-label{
    font-size: 11px;
  }
  .filter-item .p-dropdown-trigger{
    width: 10px;
  }  
  .map-summary{    
    row-gap: 50px;
  }

}