@font-face{
    font-family: 'Engravers\' Gothic';
    src: url('./EngraversGothicBT-Regular.woff2') format('woff2'),
        url('./EngraversGothicBT-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family: 'Houstiq';
    src: url('./Houstiq-Regular.woff2') format('woff2'),
        url('./Houstiq-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family: 'Athelas';
    src: url('./Athelas-Regular.woff2') format('woff2'),
        url('./Athelas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family: 'Magonda';
    src: url('./MagondaRegular.woff2') format('woff2'),
        url('./MagondaRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'flawlushregular';
    src: url('./flawlush-regular-webfont.woff2') format('woff2'),
         url('./flawlush-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}